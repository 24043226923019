"use client";

import { ArrowRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { useIntl } from "react-intl";

const LandingPage = () => {
  const intl = useIntl();

  return (
    <div>
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm/6 text-neutral-11 outline outline-1 outline-neutral-a-7">
              {intl.formatMessage(
                {
                  defaultMessage: "Forerunner Cloud is in stealth mode. {contactUs}",
                  id: "28i3Dd",
                },
                {
                  contactUs: (
                    <Link
                      className="group inline-flex items-center text-neutral-12"
                      href="mailto:org@spear.ai?subject=Forerunner%20Cloud"
                    >
                      {intl.formatMessage({
                        defaultMessage: "Contact us",
                        id: "WnQ/AZ",
                      })}
                      <ArrowRightIcon className="ms-1 transition-transform duration-200 group-hover:translate-x-0.5" />
                    </Link>
                  ),
                },
              )}
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-balance text-5xl font-semibold tracking-tight text-white sm:text-7xl">
              {intl.formatMessage({
                defaultMessage: "Changing the way we visualize sound.",
                id: "bs2PTE",
              })}
            </h1>
            <p className="mt-8 text-pretty text-lg font-medium text-neutral-12 sm:text-xl/8">
              {intl.formatMessage({
                defaultMessage:
                  "The acoustic visualization platform that’s AI first. Label, classify, and detect objects in real-time from streaming sound data.",
                id: "8Ixfe7",
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
